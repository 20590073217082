// config

$page-scroll-mode: scroll;
//? scroll : always show scrollbar
//? auto   : show scollbar when necessary

// external colors
@import "./utils/Colors.scss";

// revamp
* {
  box-sizing: border-box;
}

:root {
  --color-primary: #feca50;
  --color-primary-soft: #f7dea5;
  --color-secondary: #e7e1d7;
  --color-white: #f8f8f7;
  --color-white-soft: #f1f0ef;
  --color-dark: #363636;
  --color-lightgray: #e4e2de;
  --color-selection: #e2d7c0aa;
}

::selection {
  background: var(--color-selection);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Noto Sans TC', sans-serif;
}

a {
  color: var(--color-dark);
  text-decoration: none;
}

body,
#root {
  width: 100dvw;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  background: var(--color-white);
  overflow: hidden;
}

.main-wrapper {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: #{$page-scroll-mode};
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth !important;
}

.page-content-wrapper {
  flex-grow: 1;
}

// utils
.circle {
  border-radius: 50%;
}

.clean-link {
  text-decoration: none;
  color: unset;
}

$section-title-skew: 30;

.section-title {
  display: flex;
  margin: 1.6em auto;
  font-size: 1.4em;
  width: fit-content;
  height: 2em;
  transform: skewX(-#{$section-title-skew}deg);
  justify-content: center;
  align-items: center;
  background: var(--color-primary);
}

.section-title>* {
  margin: auto 0.5em;
  transform: skewX(#{$section-title-skew}deg);
}

.text-paragraph {
  padding: 2em 3em;
  border-radius: 10px;
  background: var(--color-white-soft);
}

@media (max-width: 768px) {
  .text-paragraph {
    padding: 20px 10px;
  }

  .text-paragraph ul {
    padding-left: 20px;
  }
}

.text-paragraph h3 {
  margin-bottom: 0.6em;
}

.text-paragraph p {
  margin-bottom: 0.4em;
}
.text-paragraph p:is(:last-child) {
  margin-bottom: 1em;
}

@for $i from 1 to 10 {
  .text-paragraph .tab-#{$i} {
    padding-left: #{$i}em;
    padding-right: #{$i}em;
  }

  @media (max-width: 768px) {
    .text-paragraph .tab-#{$i} {
      padding-left: #{$i * 2}px;
      padding-right: #{$i * 2}px;
    }
  }
}

#title-bar {
  font-size: 2em;
  width: 100%;
  padding: .8em;
  text-align: center;
  color: var(--color-dark);
  background-color: var(--color-secondary);
  margin-bottom: 1em;
}

// scrollbar
@media (min-width: 992px) {
  .scrollbar {
    overflow-y: scroll;
  }

  .scrollbar::-webkit-scrollbar {
    width: 1em;
  }

  .scrollbar::-webkit-scrollbar:has(.scrollbar-hover-section:hover) {
    display: block;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 1em;
    opacity: 0;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background-color: var(--color-lightgray);
    background-clip: padding-box;
    border: 3px solid transparent;
    border-radius: 100vw;
    min-height: 3em;
  }

  .thin-scrollbar {
    @extend .scrollbar;
  }

  .thin-scrollbar::-webkit-scrollbar {
    width: .6em;
  }

  .thin-scrollbar::-webkit-scrollbar-track {
    border-radius: .6em;
  }
}

// fade-in animation
@keyframes firstSlide {
  0% {
    transform: translateY(1200px);
  }

  20% {
    transform: translateY(80px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes firstFade {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes mainSlide {
  0% {
      transform: translate3d(0, 1200px, 0);
  }

  20% {
      transform: translate3d(0, 120px, 0);
  }

  100% {
      transform: translate3d(0, 0, 0);
  }
}

@keyframes mainFade {
  0% {
      opacity: 0;
  }

  40% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}

header {
  animation: mainFade 0.75s;
}

footer {
  animation: mainFade 1.25s;
}

.page-content-wrapper > section:is(:first-child) {
  animation: firstSlide 0.75s, firstFade 1s;
}

section:not(.page-content-wrapper > section:is(:first-child)) {
  animation: mainSlide 0.75s, mainFade 1s;
}

.fade-in.hiding {
	opacity: 0;
}

.fade-in:not(.hiding) {
  animation: firstSlide 0.75s, firstFade 0.75s;
}

// button:focus {
//   box-shadow: 0 0 0 1px var(--color-dark) !important;
// }