header .navbar-nav {
	align-items: flex-end;
	white-space: nowrap;
}

header nav li {
	margin: 0px 16px;
	font-size: 20px;
	font-weight: 600;
}

.header-shrink nav li {
	margin: 0px 15px;
	font-size: 18px;
	font-weight: 600;
}

.header-shrink nav a {
	padding: 5px;
}

// strange offcanvas bug
@media (min-width: 992px) {
	.offcanvas {
		display: none;
	}
}

.navbar-toggler, .offcanvas-close {
	box-shadow: none !important;
}

.navbar-toggler {
	border: none;
	padding: 0;
}

.navbar-toggler-icon {
	--size: 2em;
	width: var(--size);
	height: var(--size);
}
.header-shrink .navbar-toggler-icon {
	--size: 1.8em;
}

header {
	z-index: 255;
	// box-shadow: 0 1px 5px #635d64;
}
