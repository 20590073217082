#intro > .jumbotron {
	height: 80vh;
	margin-bottom: 0px;
	background-color: var(--color-dark);
	background-image: url("/../public/images/homepage/cover0.png");
	background-size: cover;
	background-position: center top;
	background-repeat: no-repeat;
}
#intro h1 {
	font-size: 75px;
	font-weight: 600;
	color: var(--color-white);
	margin: 14px 0px;
	text-shadow: 0 0 10px var(--color-dark);
}
#intro h2 {
	font-size: 22px;
	font-weight: 400;
	color: var(--color-white);
}
#intro .btn {
	margin-top: 20px;
	padding: 10px 20px;
	font-weight: 500;
	text-shadow: 0 0 3px var(--color-primary);
	border-width: 2px;
	border-radius: 30px;
}

@media (max-width: 768px) {
  #intro > .jumbotron {
		height: calc(20vh + 40vw);
	}

	#intro h1 {
		font-size: 65px;
	}
	#intro h2 {
		font-size: 16px;
	}
}

#intro h1 {
  animation: firstSlide 1s, firstFade 1.25s;
}

#intro h2 {
  animation: mainSlide 1s, mainFade 1.5s;
}

#intro .btn {
  animation: mainSlide 1.25s, mainFade 1.75s;
}
