.course p {
  margin-bottom: 0.6rem;
}

.course > .slides, .course > img { 
  width: 400px;
  height: fit-content;
  border-radius: 10px; 
  flex-shrink: 0;
}

.course > img {
  border: 3px solid var(--color-lightgray);
}

.course .btn {
  font-weight: 600;
}

@media (max-width: 992px) {
  .course {
    flex-direction: column !important;
  }

  .course > .slides, .course > img {
    width: 600px;
    height: fit-content;
    max-width: 80dvw;
  }

  .course > .description {
    margin-left: 0 !important;
    margin-top: 20px;
  }
}
.slides-container {
  border: 3px solid var(--color-lightgray);
  border-radius: 8px;
  display: flex;
  height: 33em;
  overflow: hidden;
}

.slides-list {
  width: 24em;
}

.slides-list > nav {
  display: flex;
  flex-direction: column;
}

.slides-list, .slides-contents {
  overflow-y: auto;
  padding: 0 1em;
  margin: 1.6em 0.6em;
}

.slides-vertical-line {
  border: 1.5px solid var(--color-lightgray);
  border-radius: 1.5px;
  margin: 1.6em 0;
}

.slides-contents {
  flex-grow: 1;
}

.slides-contents h4 {
  margin: 1em 0;
}

.slides-contents h4:first-child {
  margin-top: 0;
}

.slides-list > nav > span {
  // border: 2px solid red;
  border-radius: 8px;
  padding: 0.5em;
  margin-bottom: 0.4em;
  transition: background-color 200ms;
  cursor: pointer;
}

.slides-list > nav > span.active {
  background-color: var(--color-secondary);
}

@media only screen and (max-width: 576px) {
  .slides-container {
    flex-direction: column;
    height: unset;
    border: none;
  }

  .slides-contents, .slides-list {
    margin: 0.4em 0.2em;
  }

  .slides-list, .slides-vertical-line {
    display: none;
  }

  .slides-contents {
    height: unset;
  }
}
