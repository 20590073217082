/*---------------------------------------*\
|                                         |
|             ===============             |
|              Color Library              |
|             ===============             |
|                                         |
|    https://htmlcolorcodes.com/colors/   |
|                                         |
\.---------------------------------------*/

//! Only uncomment used colors!
$colors: (
  // "black": #000000,
  // "charcoal": #36454F,
  // "dark-green": #023020,
  // "dark-purple": #301934,
  // "jet-black": #343434,
  // "licorice": #1B1212,
  // "matte-black": #28282B,
  // "midnight-blue": #191970,
  // "onyx": #353935,
  // "aqua": #00FFFF,
  // "azure": #F0FFFF,
  // "baby-blue": #89CFF0,
  // "blue": #0000FF,
  // "blue-gray": #7393B3,
  // "blue-green": #088F8F,
  // "bright-blue": #0096FF,
  // "cadet-blue": #5F9EA0,
  // "cobalt-blue": #0047AB,
  // "cornflower-blue": #6495ED,
  // "cyan": #00FFFF,
  // "dark-blue": #00008B,
  // "denim": #6F8FAF,
  // "egyptian-blue": #1434A4,
  // "electric-blue": #7DF9FF,
  // "glaucous": #6082B6,
  // "jade": #00A36C,
  // "indigo": #3F00FF,
  "iris": #5D3FD3,
  // "light-blue": #ADD8E6,
  // "navy-blue": #000080,
  // "neon-blue": #1F51FF,
  // "pastel-blue": #A7C7E7,
  // "periwinkle": #CCCCFF,
  // "powder-blue": #B6D0E2,
  // "robin-egg-blue": #96DED1,
  // "royal-blue": #4169E1,
  // "sapphire-blue": #0F52BA,
  // "seafoam-green": #9FE2BF,
  // "sky-blue": #87CEEB,
  // "steel-blue": #4682B4,
  // "teal": #008080,
  // "turquoise": #40E0D0,
  // "ultramarine": #0437F2,
  // "verdigris": #40B5AD,
  // "zaffre": #0818A8,
  // "almond": #EADDCA,
  // "brass": #E1C16E,
  // "bronze": #CD7F32,
  // "brown": #A52A2A,
  // "buff": #DAA06D,
  // "burgundy": #800020,
  // "burnt-sienna": #E97451,
  // "burnt-umber": #6E260E,
  // "camel": #C19A6B,
  // "chestnut": #954535,
  // "chocolate": #7B3F00,
  // "cinnamon": #D27D2D,
  // "coffee": #6F4E37,
  // "cognac": #834333,
  // "copper": #B87333,
  // "cordovan": #814141,
  // "dark-brown": #5C4033,
  // "dark-red": #8B0000,
  // "dark-tan": #988558,
  // "ecru": #C2B280,
  // "fallow": #C19A6B,
  // "fawn": #E5AA70,
  // "garnet": #9A2A2A,
  // "golden-brown": #966919,
  // "khaki": #F0E68C,
  // "light-brown": #C4A484,
  // "mahogany": #C04000,
  // "maroon": #800000,
  // "mocha": #967969,
  // "nude": #F2D2BD,
  // "ochre": #CC7722,
  // "olive-green": #808000,
  // "oxblood": #4A0404,
  // "puce": #A95C68,
  // "red-brown": #A52A2A,
  // "red-ochre": #913831,
  // "russet": #80461B,
  // "saddle-brown": #8B4513,
  // "sand": #C2B280,
  // "sienna": #A0522D,
  // "tan": #D2B48C,
  // "taupe": #483C32,
  // "tuscan-red": #7C3030,
  // "wheat": #F5DEB3,
  // "wine": #722F37,
  // "ash-gray": #B2BEB5,
  // "dark-gray": #A9A9A9,
  // "gray": #808080,
  // "gunmetal-gray": #818589,
  // "light-gray": #D3D3D3,
  // "pewter": #899499,
  // "platinum": #E5E4E2,
  // "sage-green": #8A9A5B,
  // "silver": #C0C0C0,
  // "slate-gray": #708090,
  // "smoke": #848884,
  // "steel-gray": #71797E,
  // "aquamarine": #7FFFD4,
  // "army-green": #454B1B,
  // "bright-green": #AAFF00,
  // "cadmium-green": #097969,
  // "celadon": #AFE1AF,
  // "chartreuse": #DFFF00,
  // "citrine": #E4D00A,
  // "emerald-green": #50C878,
  // "eucalyptus": #5F8575,
  // "fern-green": #4F7942,
  // "forest-green": #228B22,
  // "grass-green": #7CFC00,
  // "green": #008000,
  // "hunter-green": #355E3B,
  // "jungle-green": #2AAA8A,
  "kelly-green": #4CBB17,
  // "light-green": #90EE90,
  // "lime-green": #32CD32,
  // "lincoln-green": #478778,
  // "malachite": #0BDA51,
  // "mint-green": #98FB98,
  // "moss-green": #8A9A5B,
  // "neon-green": #0FFF50,
  // "nyanza": #ECFFDC,
  // "pastel-green": #C1E1C1,
  // "pear": #C9CC3F,
  // "peridot": #B4C424,
  // "pistachio": #93C572,
  // "sea-green": #2E8B57,
  // "shamrock-green": #009E60,
  // "spring-green": #00FF7F,
  // "vegas-gold": #C4B454,
  // "viridian": #40826D,
  // "amber": #FFBF00,
  // "apricot": #FBCEB1,
  // "bisque": #F2D2BD,
  // "bright-orange": #FFAC1C,
  // "burnt-orange": #CC5500,
  // "butterscotch": #E3963E,
  // "cadmium-orange": #F28C28,
  // "coral": #FF7F50,
  // "coral-pink": #F88379,
  // "dark-orange": #8B4000,
  // "desert": #FAD5A5,
  "gamboge": #E49B0F,
  // "golden-yellow": #FFC000,
  // "goldenrod": #DAA520,
  // "light-orange": #FFD580,
  "mango": #F4BB44,
  // "navajo-white": #FFDEAD,
  // "neon-orange": #FF5F1F,
  // "orange": #FFA500,
  // "pastel-orange": #FAC898,
  // "peach": #FFE5B4,
  // "persimmon": #EC5800,
  // "pink-orange": #F89880,
  // "poppy": #E35335,
  // "pumpkin-orange": #FF7518,
  // "red-orange": #FF4433,
  // "safety-orange": #FF5F15,
  // "salmon": #FA8072,
  // "seashell": #FFF5EE,
  // "sunset-orange": #FA5F55,
  // "tangerine": #F08000,
  // "terra-cotta": #E3735E,
  // "yellow-orange": #FFAA33,
  // "amaranth": #9F2B68,
  // "cerise": #DE3163,
  // "claret": #811331,
  "crimson": #DC143C,
  // "dark-pink": #AA336A,
  // "dusty-rose": #C9A9A6,
  // "fuchsia": #FF00FF,
  // "hot-pink": #FF69B4,
  // "light-pink": #FFB6C1,
  // "magenta": #FF00FF,
  // "millennial-pink": #F3CFC6,
  // "mulberry": #770737,
  // "neon-pink": #FF10F0,
  "orchid": #DA70D6,
  // "pastel-pink": #F8C8DC,
  // "pastel-red": #FAA0A0,
  // "pink": #FFC0CB,
  // "plum": #673147,
  // "purple": #800080,
  // "raspberry": #E30B5C,
  // "red-purple": #953553,
  // "rose": #F33A6A,
  // "rose-gold": #E0BFB8,
  // "rose-red": #C21E56,
  // "ruby-red": #E0115F,
  // "thistle": #D8BFD8,
  // "watermelon-pink": #E37383,
  // "bright-purple": #BF40BF,
  // "byzantium": #702963,
  // "eggplant": #483248,
  // "lavender": #E6E6FA,
  // "light-purple": #CBC3E3,
  // "light-violet": #CF9FFF,
  // "lilac": #AA98A9,
  // "mauve": #E0B0FF,
  // "mauve-taupe": #915F6D,
  // "pastel-purple": #C3B1E1,
  // "quartz": #51414F,
  // "tyrian-purple": #630330,
  // "violet": #7F00FF,
  // "wisteria": #BDB5D5,
  // "blood-red": #880808,
  // "brick-red": #AA4A44,
  "bright-red": #EE4B2B,
  // "cadmium-red": #D22B2B,
  // "cardinal-red": #C41E3A,
  // "carmine": #D70040,
  // "cherry": #D2042D,
  // "falu-red": #7B1818,
  // "marsala": #986868,
  // "neon-red": #FF3131,
  // "red": #FF0000,
  // "scarlet": #FF2400,
  // "venetian-red": #A42A04,
  // "vermillion": #E34234,
  // "alabaster": #EDEADE,
  // "beige": #F5F5DC,
  // "bone-white": #F9F6EE,
  // "cornsilk": #FFF8DC,
  // "cream": #FFFDD0,
  // "eggshell": #F0EAD6,
  // "ivory": #FFFFF0,
  // "linen": #E9DCC9,
  // "off-white": #FAF9F6,
  // "parchment": #FCF5E5,
  // "pearl": #E2DFD2,
  // "vanilla": #F3E5AB,
  // "white": #FFFFFF,
  "PapayaWhip": #FFEFD5,
  // "bright-yellow": #FFEA00,
  // "cadmium-yellow": #FDDA0D,
  // "canary-yellow": #FFFF8F,
  // "dark-yellow": #8B8000,
  // "flax": #EEDC82,
  // "gold": #FFD700,
  // "icterine": #FCF55F,
  // "jasmine": #F8DE7E,
  // "lemon-yellow": #FAFA33,
  // "maize": #FBEC5D,
  // "mustard-yellow": #FFDB58,
  // "naples-yellow": #FADA5E,
  // "pastel-yellow": #FFFAA0,
  // "saffron": #F4C430,
  // "yellow": #FFFF00,
);

:root {

  @each $name,
  $col in $colors {
    --ecol-#{$name}: #{$col};

    // @for $i from 1 through 20 {
    //   $opac: 5 * $i;

    //   --ecol-#{$name}-#{$opac}: rgba(var(--ecol-#{$name}), #{$opac});
    // }
  }
}

@each $name, $col in $colors {
  .bg-#{$name} {
    background-color: rgba(var(--ecol-#{$name}), 100%);
  }

  .btn-#{$name} {
    $text-col: var(--color-white);
    @if (lightness($col) > 60%) {
      $text-col: var(--color-dark);
    }

    --bs-btn-color: #{$text-col};
    --bs-btn-bg: #{$col};
    --bs-btn-border-color: #{darken($col, 5%)};
    --bs-btn-hover-color: #{$text-col};
    --bs-btn-hover-bg: #{darken($col, 5%)};
    --bs-btn-hover-border-color: #{darken($col, 10%)};
    --bs-btn-focus-shadow-rgb: var(--color-dark);
    --bs-btn-active-color: #{$text-col};
    --bs-btn-active-bg: #{lighten($col, 5%)};
    --bs-btn-active-border-color: #{$col};
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #{$text-col};
    --bs-btn-disabled-bg: #{hsl(hue($col), 50%, lightness($col))};
    --bs-btn-disabled-border-color: #{hsl(hue($col), 50%, lightness(darken($col, 20%)))};
  }
}
