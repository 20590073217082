#map .row > .col-md-3 {
	margin-bottom: 2em;
}

#map .card h5 {
	font-size: 24px;
}
#map .card p {
	margin-bottom: 20px;
}
#map .card .btn {
	font-weight: 600;
}

#map .card {
	height: 100%;
}
#map .card .card-text {
	margin-bottom: 60px;
}
#map .card .btn {
	position: absolute;
	bottom: 20px;
}