#museum .container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.museum-item {
  width: 991px;
  max-width: 85dvw;
  display: flex;
  margin-bottom: 3em;
}

.museum-item img {
  border: 4px solid var(--color-lightgray);
  border-radius: 6px;
  object-fit: cover;
  max-width: 86dvw;
}

.museum-item .vertical-line {
  width: 0;
  border: 2px solid var(--color-lightgray);
  border-radius: 4px;
  margin: 0 16px
}

.museum-item h4 {
  margin-bottom: 1rem;
}

.museum-item p:is(:last-child) {
  margin-bottom: 0;
}

@media only screen and (max-width: 792px) {
  .museum-item {
    flex-direction: column;
  }

  .museum-item img {
    margin-bottom: 18px;
  }

  .museum-item .vertical-line {
    display: none;
  }

  .museum-item img {
    border: 2px solid var(--color-lightgray);
    border-radius: 3px;
  }
}